import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ValidateCodeInput from '../../components/Form/CodeInput';
import Input from '../../components/Form/Input';
import LoginWrap from '../../components/LoginWrap';
import SEO from '../../components/SEO';
import { INVITATION, SHARE_CODE_STORE_KEY, V_CODE_SEND } from '../../constants';
import { register } from '../../services';
import { RegisterBody } from '../../services/interface';

import * as styles from './index.module.less';

export default function RegisterPage() {
  const [loading, setLoading] = useState(false);
  const [shareCode, setShareCode] = useState<string>();
  const [formValue, setFormValue] = useState<RegisterBody & { confirmPassword: string }>({
    phone: '',
    password: '',
    confirmPassword: '',
    email: '',
    code: undefined,
    send_type: 1,
    invitation_code: shareCode || '',
    invitation_type: shareCode ? INVITATION.SHARE_CODE : INVITATION.CODE,
  });

  function submitHandler(e: React.FormEvent) {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    register({ ...formValue, code: Number(formValue.code) })
      .then(() => {
        navigate(`/register-progress?username=${formValue.email}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const code = window.localStorage.getItem(SHARE_CODE_STORE_KEY);
    if (code) {
      // 获取邀请码在注册时传入
      setShareCode(code);
    }
  }, []);

  return (
    <LoginWrap
      title="注册"
      otherLink={
        <>
          <Link to="/password">忘记密码</Link>
          <Link to="/login">登录</Link>
          <Link to="/probation">申请试用</Link>
        </>
      }>
      <form onSubmit={submitHandler}>
        <div className={styles.formItem}>
          <Input
            type="email"
            name="email"
            required
            placeholder="邮箱"
            value={formValue.email}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                email: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            type="password"
            name="password"
            required
            autoComplete="new-password"
            placeholder="8-50 位密码，区分大小写"
            value={formValue.password}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                password: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
            required
            pattern={`^${formValue.password}$`}
            title="两次输入的密码不一致"
            placeholder="确认密码"
            value={formValue.confirmPassword}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                confirmPassword: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            type="phone"
            name="phone"
            required
            placeholder="11 位手机号"
            value={formValue.phone}
            pattern="^(?:(?:\+|00)86)?1\d{10}$"
            title="手机号码格式不正确"
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                phone: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <ValidateCodeInput
            phone={formValue.phone}
            sendType={V_CODE_SEND.REGISTER.value}
            placeholder="请输入验证码"
            autoComplete="off"
            required
            name="code"
            value={formValue.code?.toString() || ''}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                code: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            name="invitation_code"
            required
            placeholder="邀请码"
            value={formValue.invitation_code}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                invitation_code: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <Button type="primary" block loading={loading} htmlType="submit">
            注 册
          </Button>
        </div>
      </form>
    </LoginWrap>
  );
}

export function Head() {
  return <SEO title="注册" />;
}
