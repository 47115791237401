import React from 'react';
import * as styles from './index.module.less';

export interface InputProps<T = any> extends React.InputHTMLAttributes<T> {}

export default function Input({ className, style, placeholder, type, ...inputProps }: InputProps) {
  return (
    <div className={[styles.input, className].join(' ')} style={style}>
      <div className={styles.before}></div>
      <input type={type} placeholder={placeholder} {...inputProps} />
      <div className={styles.after}></div>
    </div>
  );
}
